import { createApp } from 'vue';
import AudioBars from '@components/AudioBars.vue';
import Chat from '@components/Chat.vue';
import UnderlinedText from '@components/UnderlinedText.vue';

import '@styles/app.pcss';

const app = createApp({
    components: {
        AudioBars,
        Chat,
        UnderlinedText,
    },
});

app.mount('#app');
