<script setup>
import { computed, onMounted, ref, useSlots } from 'vue';
import { scroll } from 'motion';

const slots = useSlots();

const container = ref(null);
const words = ref([]);
const highlightedWord = ref(-1);

const accumulatedWords = computed(() => {
    const wordsPerSlot = slots
        .default()
        .map((slot) => slot.children.split(' ').length);
    return wordsPerSlot.map((numWords, index) => {
        return wordsPerSlot.slice(0, index).reduce((a, b) => a + b, 0);
    });
});

onMounted(() => {
    scroll(
        ({ y }) => {
            highlightedWord.value =
                Math.floor(y.progress * (words.value.length + 1)) - 1;
        },
        {
            target: container.value,
            offset: ['25% 75%', '100% 50%'],
        }
    );
});
</script>

<template>
    <div class="py-10 section space-y-10" ref="container">
        <component
            v-for="(slot, paragraphIndex) in slots.default()"
            :is="slot.type"
        >
            <template v-for="(word, wordIndex) in slot.children.split(' ')">
                <span
                    ref="words"
                    class="relative inline-block transition-colors duration-100"
                    :class="{
                        'text-primary-500':
                            highlightedWord >=
                            accumulatedWords[paragraphIndex] + wordIndex,
                    }"
                >
                    {{ word }}
                    <span
                        class="absolute left-0 top-full -mt-1 h-1.5 rounded-full bg-primary-300 transition-[opacity,right] ease-in-out"
                        :class="{
                            'opacity-0 duration-100 right-full':
                                highlightedWord <
                                accumulatedWords[paragraphIndex] + wordIndex,
                            'opacity-100 duration-300 right-0':
                                highlightedWord ===
                                accumulatedWords[paragraphIndex] + wordIndex,
                            'opacity-20 duration-300 right-0':
                                highlightedWord >
                                accumulatedWords[paragraphIndex] + wordIndex,
                        }"
                    />
                </span>
                {{ ' ' }}
            </template>
        </component>
    </div>
</template>
